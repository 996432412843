import request from '@/utils/request'


// 查询用户道具使用记录列表
export function listPropUse(query) {
  return request({
    url: '/user/user-prop-use/list',
    method: 'get',
    params: query
  })
}

// 查询用户道具使用记录分页
export function pagePropUse(query) {
  return request({
    url: '/user/user-prop-use/page',
    method: 'get',
    params: query
  })
}

// 查询用户道具使用记录详细
export function getPropUse(data) {
  return request({
    url: '/user/user-prop-use/detail',
    method: 'get',
    params: data
  })
}

// 新增用户道具使用记录
export function addPropUse(data) {
  return request({
    url: '/user/user-prop-use/add',
    method: 'post',
    data: data
  })
}

// 修改用户道具使用记录
export function updatePropUse(data) {
  return request({
    url: '/user/user-prop-use/edit',
    method: 'post',
    data: data
  })
}

// 删除用户道具使用记录
export function delPropUse(data) {
  return request({
    url: '/user/user-prop-use/delete',
    method: 'post',
    data: data
  })
}
